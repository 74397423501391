import { UtilityService } from '.';
import { ApiService } from './api.service';
import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import {
    ExamSubscriptionRequestModel,
    PackageDataModel,
    PackageRecommendationModel,
    PackageRecommendationRequestModel,
    PackageSubscriptionRequestModel,
} from '../models/package.model';
import { PackagesSelectionOption } from 'app/models/packages-model';
import { PackageType, PaymentIntentStatus } from 'app/constants';
import { StripePaymentIntent } from 'app/models/payment-intent.model';
import { PaymentInfoForForRedirect } from 'app/models/payment-info-for-redirect.model';

@Injectable({
    providedIn: 'root'
})
export class PackagesFlowService {
    packageRecommendation: PackageRecommendationRequestModel
    package: PackageRecommendationModel;

    selectedDuration: PackagesSelectionOption;
    selectedLessonsPerWeek: PackagesSelectionOption;
    selectedTutorSeniority: PackagesSelectionOption;

    periodOptions: Array<PackagesSelectionOption>;
    sequenceOptions: Array<PackagesSelectionOption>;
    tutorSeniorityLevels: Array<PackagesSelectionOption>;

    totalCredits: string;

    //package = pending & payment intend = pending
    pendingPackage: PackageDataModel;

    suspendedPackage: PackageDataModel;

    constructor(
        private apiService: ApiService,
        private utilityService: UtilityService
    ) {
        this.packageRecommendation = {
            durationMonths: '4',
            lessonsPerWeek: '1',
            tutorCategory: '2',
            country: this.utilityService.country,
            improvementPurpose: '',
        }
    }

    getPackagesRecommendation(payload: PackageRecommendationRequestModel): Observable<PackageRecommendationModel> {
        return this.apiService.post('api/booking/packages/package-recommendation', payload)
    }

    getUserPackages(userId: number): Observable<{packages: Array<PackageDataModel>}> {
        return this.apiService.get(`api/booking/packages/user-packages/current?userId=${userId}&statuses=[1,2,3,4]&cacheBuster=${Date.now()}`)
            .pipe(
                map(response => {
                    response.packages = response.packages.map(p => {
                        const _package = p.userPackage;
                        if (!_package.balance) {
                            _package.balance = "0";
                        }
                        return p;
                    });
                    return response;
                }),
                tap(response => {
                    const pendingPackages = response.packages.filter(x => x.userPackage.status == PackageType.Pending && 
                        (x.paymentIntent.status == PaymentIntentStatus.Pending || x.paymentIntent.status == PaymentIntentStatus.Canceled ));
                    if (pendingPackages.length) {
                        if(pendingPackages.length > 1) {
                            this.pendingPackage = pendingPackages.find(x => x.paymentIntent.currentInstallment != '1') || pendingPackages[0];
                        } else {
                            this.pendingPackage = pendingPackages[0];
                        }
                    }
                    this.suspendedPackage = response.packages.find(x => x.userPackage.status == PackageType.Suspended);
                    let activePackages = response.packages.filter(x => x.userPackage.status == PackageType.Active);
                    activePackages = activePackages.sort((a, b) => a.userPackage.status - b.userPackage.status);
                    this.totalCredits = activePackages.length ? (activePackages.map(x => +x.userPackage.balance).reduce((a, b) => a + b)).toString() : '';
                }),
            );
    }

    subscribeToPackage(data: PackageSubscriptionRequestModel): Observable<PackageDataModel> {
        return this.apiService.post(`api/booking/packages/user-packages/subscribe`, data)
    }

    getExamPackages(tutorCategory: string): Observable<{ offers: Array<PackageRecommendationModel> }> {
        return this.apiService.post('api/booking/packages/exam-package-recommendation', { tutorCategory })
    }

    subscribeToExamPackage(data: ExamSubscriptionRequestModel): Observable<PackageDataModel> {
        return this.apiService.post(`api/booking/packages/user-packages/subscribe-exam-package`, data)
    }

    initiatePaymentIntent(userPackageId: string): Observable<StripePaymentIntent> {
        return this.apiService.post(`api/booking/payment/intent/${userPackageId}`)
    }

    clearUserPackages() {
        this.totalCredits = '';
    }

    saveInfoInLocalStorageForRedirect(paymentInfoForRedirect: PaymentInfoForForRedirect) {
        localStorage?.setItem("paymentInfoForRedirect", JSON.stringify(paymentInfoForRedirect));
    }
}

