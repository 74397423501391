<div class="faq-list" [ngClass]="{'chat-page-styling': isChatPage, 'landing-page-styling': isLandingPage}">
    <div class="list-title">
        {{ faqTitle }}
    </div>
    <div *ngIf="faqListItems">
        <div *ngFor="let faqItem of faqListItems; let i = index">
            <app-custom-expansion-panel #expansionPanel [isExpanded]="expandedPanelIndex === i"
                (onHeaderClicked)="setStep(i)">
                <div header class="expansion-header">
                    <div>
                        {{ faqItem.title }}
                    </div>
                    <div class="icon icon--closed" *ngIf="expandedPanelIndex !== i">
                        <i class="fas fa-plus"></i>
                    </div>
                    <div class="icon icon--expanded" *ngIf="expandedPanelIndex === i">
                        <i class="fas fa-minus"></i>
                    </div>
                </div>
                <div expandedContent class="expansion-body">
                    <div class="subcontent-text content" [bindHTML]="faqItem.content"></div>
                    <div *ngIf="isChatPage">
                        <div class="subtitle-text content" [bindHTML]="faqItem.subTitle1"></div>
                        <div class="subcontent-text content" [bindHTML]="faqItem.subContent1"></div>
                        <div class="subtitle-text content" [bindHTML]="faqItem.subTitle2"></div>
                        <div class="subcontent-text content" [bindHTML]="faqItem.subContent2"></div>
                        <div class="subtitle-text content" [bindHTML]="faqItem.subTitle3"></div>
                        <div class="subcontent-text content" [bindHTML]="faqItem.subContent3"></div>
                        <div class="subtitle-text content" [bindHTML]="faqItem.subTitle4"></div>
                        <div class="subcontent-text content" [bindHTML]="faqItem.subContent4"></div>
                    </div>
                    <div *ngIf="isLandingPage">
                        <ul *ngIf="faqItem.listTitle1">
                            <li>
                                <div class="list-item">
                                    <span class="list-title" [bindHTML]="faqItem.listTitle1"></span>&nbsp;<span
                                        class="subcontent-text content" [bindHTML]="faqItem.listContent1"></span>
                                </div>
                            </li>
                            <li>
                                <div class="list-item">
                                    <span class="list-title" [bindHTML]="faqItem.listTitle2"></span>&nbsp;<span
                                        class="subcontent-text content" [bindHTML]="faqItem.listContent2"></span>
                                </div>
                            </li>
                            <li>
                                <div class="list-item">
                                    <span class="list-title" [bindHTML]="faqItem.listTitle3"></span>&nbsp;<span
                                        class="subcontent-text content" [bindHTML]="faqItem.listContent3"></span>
                                </div>
                            </li>
                            <li>
                                <div class="list-item">
                                    <span class="list-title" [bindHTML]="faqItem.listTitle4"></span>&nbsp;<span
                                        class="subcontent-text content" [bindHTML]="faqItem.listContent4"></span>
                                </div>
                            </li>
                            <li>
                                <div class="list-item">
                                    <span class="list-title" [bindHTML]="faqItem.listTitle5"></span>&nbsp;<span
                                        class="subcontent-text content" [bindHTML]="faqItem.listContent5"></span>
                                </div>
                            </li>
                            <li>
                                <div class="list-item">
                                    <span class="list-title" [bindHTML]="faqItem.listTitle6"></span>&nbsp;<span
                                        class="subcontent-text content" [bindHTML]="faqItem.listContent6"></span>
                                </div>
                            </li>
                        </ul>
                        <div class="subcontent-text content" [bindHTML]="faqItem.subContent"></div>
                    </div>
                </div>
            </app-custom-expansion-panel>
        </div>
    </div>
</div>