import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Course } from 'app/models/search-school.model';
import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'app-subjects-checkbox-list',
  standalone: true,
  imports: [CommonModule, CheckboxComponent],
  templateUrl: './subjects-checkbox-list.component.html',
  styleUrls: ['./subjects-checkbox-list.component.scss']
})
export class SubjectsCheckboxListComponent {
  @Input() availableCourses: Array<Course>;
  @Input() selectedCourseIds: number[] | null;
  @Output() onSelect = new EventEmitter<Course>();
}
