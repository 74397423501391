import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/modules/shared/shared.module';
import { SequenceOptions, TutorSeniorityLevels } from 'app/constants/packages';
import { AuthService, ToastService, UtilityService } from 'app/services';
import { MessageType } from 'app/constants';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-buy-package-after-booking',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './buy-package-after-booking.component.html',
  styleUrls: ['./buy-package-after-booking.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1100ms ease-in-out', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class BuyPackageAfterBookingComponent implements OnInit {
  @Output() nextStep = new EventEmitter();

  constructor(
    private toastService: ToastService,
    public authService: AuthService,
    private utilityService: UtilityService
  ) { }

  ngOnInit() {
    this.utilityService.pushEventVWO("packageDialogFirstPage")
  }

  @Input() showPictureAndSvg: boolean = true;
  @Input() showTutorLevelOptions: boolean = true;
  @Input() applyCustomStyling: boolean = false;
  @Input() showOnly5HourPackage: boolean = false;
  lessonsPerWeek = SequenceOptions;
  tutorLevels = TutorSeniorityLevels;
  selectedLessonPerWeek;
  @Input() tutorName: string;
  @Input() selectedTutorLevel: string;

  lessonsPerWeekChange(selectedLessonPerWeek) {
    this.selectedLessonPerWeek = selectedLessonPerWeek.value
  }

  tutorLevelChange(selectedTutorLevel) {
    this.selectedTutorLevel = selectedTutorLevel.level
  }

  openNextStep() {
    if (this.showOnly5HourPackage) {
      this.nextStep.emit({ lessonPerWeek: null, selectedTutorLevel: this.selectedTutorLevel });
    } else if (this.selectedLessonPerWeek && (this.selectedTutorLevel)) {
      this.nextStep.emit({ lessonPerWeek: this.selectedLessonPerWeek, selectedTutorLevel: this.selectedTutorLevel.toString() });
    } else {
      this.toastService.showMsg('texts.Buy_Package_After_Booking_Options_Button_Disabled', MessageType.info)
    }
  }
}
