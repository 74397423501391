import { NgModule } from "@angular/core";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
//TODO move here all of the material modules from the app
@NgModule({
        declarations: [],
        exports: [
                MatTooltipModule,
                MatDialogModule,
                MatProgressSpinnerModule,
                MatTableModule,
                MatDatepickerModule,
                MatInputModule,
                MatNativeDateModule,
                MatFormFieldModule,
                MatSelectModule
        ],
})
export class MaterialModule { }