import { Injectable } from '@angular/core';
import { UserStateEnum } from 'app/microservice-clients/user';
import { AuthService, PopupService } from 'app/services';
import { DetailsForCompleteProfile } from '../models/additional-information.model';
import { AdditionalInformationDialogComponent } from '../additional-information-dialog/additional-information-dialog.component';

@Injectable({
    providedIn: 'any'
})
export class CheckUserState {
    constructor(
        private authService: AuthService,
        private popupService: PopupService

    ) { }
    public checkIfUserProfileCompleteDialog(completeProfileData: DetailsForCompleteProfile): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                if (this.authService.userDetails?.state === UserStateEnum.New) {
                    const dialog = this.popupService.openResponsiveDialog(AdditionalInformationDialogComponent)
                    dialog.afterOpened().subscribe(() => {
                        dialog.componentInstance.additionalInformation.detailsForCompleteProfile = completeProfileData
                        dialog.componentInstance.onProfileCompleted.subscribe(() => {
                            resolve()
                        })
                    })
                    return
                }
                resolve()
            }, 400)
        })
    }
}