import { Inject, Injectable, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as cookie from 'cookie';
import { REQUEST, RESPONSE } from 'express.tokens';
import { UtilityService } from './utility.service';

export interface CookieOptions {
  expires?: string | Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: 'Lax' | 'Strict' | 'None';
}

@Injectable({
  providedIn: 'root'
})
export class CustomCookieServiceService {

  constructor(
    @Optional() @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject(REQUEST) private request: any,
    @Optional() @Inject(RESPONSE) private response: any,
    private utiltyService: UtilityService
  ) {
    const sessionIdFromCookies = this.get('sessionIdForVWO');
    if (!sessionIdFromCookies) {
      this.set('sessionIdForVWO', this.utiltyService.sessionId, 100);
    }
  }

  set(name: string, value: string, days: number): void {
    const expires = new Date(Date.now() + days * 864e5).toUTCString();
    const cookieString = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;

    if (this.utiltyService.isBrowser) {
      this.document.cookie = cookieString;
    } else {
      this.response.setHeader('Set-Cookie', cookieString);
    }
  }

  get(name: string): string {
    if (this.utiltyService.isBrowser) {
      return this.document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=');
        return parts[0] === name ? decodeURIComponent(parts[1]) : r;
      }, '');
    } else {
      const cookies = cookie.parse(this.request.headers.cookie || '');
      return cookies[name] || '';
    }
  }

  remove(name: string): void {
    this.set(name, '', -1);
  }

  hasKey(name: string): boolean {
    if (this.utiltyService.isBrowser) {
      return this.document.cookie.split('; ').some(cookie => cookie.startsWith(`${name}=`));
    } else {
      const cookies = cookie.parse(this.request.headers.cookie || '');
      return name in cookies;
    }
  }

  put(name: string, value: string, options?: CookieOptions): void {
    let cookieString = `${name}=${encodeURIComponent(value)}`;

    if (options) {
      if (options.expires) {
        const expires = typeof options.expires === 'string' ? options.expires : options.expires.toUTCString();
        cookieString += `; expires=${expires}`;
      }
      if (options.path) {
        cookieString += `; path=${options.path}`;
      }
      if (options.domain) {
        cookieString += `; domain=${options.domain}`;
      }
      if (options.secure) {
        cookieString += `; secure`;
      }
      if (options.sameSite) {
        cookieString += `; samesite=${options.sameSite}`;
      }
    }

    if (this.utiltyService.isBrowser) {
      this.document.cookie = cookieString;
    } else {
      this.response.setHeader('Set-Cookie', cookieString);
    }
  }
}