import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SharedModule } from 'app/modules/shared/shared.module';

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent {

  @Input() checked: boolean;
  @Input() customId: string;
  @Input() customName: string;
  @Input() checkboxSize: number = 20;
  @Output() onChange = new EventEmitter<boolean>();
  @ViewChild('checkbox') checkbox: ElementRef;

  toggleCheckbox() {
    this.checked = !this.checked;
    this.onChange.emit(this.checked);
  }
}
