import { SubjectsCheckboxListComponent } from './../../standalone-components/subjects-checkbox-list/subjects-checkbox-list.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './components/search/search.component';
import { SharedModule } from '../shared/shared.module';
import { MobileSearchComponent } from './components/mobile-search/mobile-search.component';
import { ToggleOnlineHomeComponent } from 'app/standalone-components/toggle-online-home/toggle-online-home.component';
import { MaterialModule } from '../material/material.module';
import { CustomExpansionPanelComponent } from 'app/standalone-components/custom-expansion-panel/custom-expansion-panel.component';
import { CheckboxComponent } from 'app/standalone-components/checkbox/checkbox.component';

@NgModule({
  declarations: [
    MobileSearchComponent,
    SearchComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ToggleOnlineHomeComponent,
    SubjectsCheckboxListComponent,
    MaterialModule,
    CustomExpansionPanelComponent,
    CheckboxComponent
  ],
  exports: [
    SearchComponent
  ]
})
export class SearchModule { }
