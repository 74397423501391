import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, EventEmitter, Output } from '@angular/core';
import { SharedModule } from 'app/modules/shared/shared.module';
import { CommonModule } from '@angular/common';
@Component({
    selector: 'app-subscribed-dialog',
    standalone: true,
    imports: [CommonModule, SharedModule],
    templateUrl: './subscribed-dialog.component.html',
    styleUrls: ['./subscribed-dialog.component.scss']
})

export class SubscribedDialogComponent {

    canNavigateToChat: boolean = false;
    showConfirmButton: boolean = false;
    showCloseButton: boolean = true

    @Output() onClose = new EventEmitter();

    constructor(
        private matDialogRef: MatDialogRef<SubscribedDialogComponent>,
        private router: Router
    ) { }

    close() {
        this.matDialogRef.close();
        this.onClose.emit();
    }

    confirm() {
        if (this.canNavigateToChat) {
            this.router.navigate(['/chat']);
        }
        this.onClose.emit();
        this.matDialogRef.close()
    }
}
