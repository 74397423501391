import { Inject, Injectable, Optional, PLATFORM_ID, TransferState, makeStateKey } from '@angular/core';
import { PageSection, PageTemplate } from '../models';
import { Meta, Title } from '@angular/platform-browser';
import { map, Observable, Subscription } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { PageConfigService } from './page-config.service';
import { ApiService } from './api.service';
import { UtilityService } from './utility.service';
import { isPlatformServer, Location } from '@angular/common';
import { REQUEST } from '../../express.tokens';
import { Request } from 'express';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PAGE_TEMPLATE } from '../tokens/page-template';
import {
	QuestionCollection
} from '../modules/cms/question-collection/question-collection-section/question-collection-section.component';
import { LogService } from './log.service';
import { BypassAPIGlobalHandleEnums, LOG_TYPE } from '../constants';

@Injectable({
	providedIn: 'root'
})
export class PageLoadService {
	pageTemplate!: PageTemplate;
	pageName!: string;
	lastURL?: string;

	location: Location;

	layoutDefinitions: { [key: string]: TemplateConfig; } = {
		'default': new TemplateConfig(true, true),
		'referral': new TemplateConfig(true, true),
		'default-content': new TemplateConfig(false, false),
		'admin': new TemplateConfig(true, true),
	};

	pageTemplate$: Observable<PageTemplate> | null = null;
	getTemplate!: Subscription;

	lastPageTemplate: PageTemplate | null = null;

	constructor(
		private apiService: ApiService,
		private metaService: Meta,
		private pageConfigService: PageConfigService,
		private utilityService: UtilityService,
		private router: Router,
		private titleService: Title,
		private translationService: TranslateService,
		@Optional() @Inject(REQUEST) private request: Request,
		@Optional() @Inject(PAGE_TEMPLATE) private pageTemplateInjected: PageTemplate,
		@Inject(PLATFORM_ID) private platformId: Object,
		location: Location,
		private logService: LogService,
		private transferState: TransferState,
	) {
		this.location = location;
		this.platformId = platformId;
		this.request = request;
	}

	// TODO: used as guard, but actually a resolver
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		let url = state.url;
		this.initPageTemplate(url);
		return true;
	}

	initPageTemplate(url: string): void {
		this.utilityService.isHeaderHidden = false;

		let stateKey = makeStateKey(url);
		if (this.proceedPageRequest(url)) {
			url = this.updateUrlIfItHasDynamicParams(url)
			if (isPlatformServer(this.platformId)) {
				this.transferState.set(stateKey, this.pageTemplateInjected as any);
			}

			this.pageTemplate$ = isPlatformServer(this.platformId) ?
				of(this.pageTemplateInjected) :
				(this.transferState.hasKey(stateKey) ?
					of(this.transferState.get(stateKey, this.pageTemplateInjected as any)) :
					this.getPageTemplate(url).pipe(
						map(p => {
							p?.sections.forEach(s => {
								s.data = typeof s.data === 'string' ? JSON.parse(s.data) : s.data;
							});
							return p;
						}),
						tap(() => {
							this.closeLoaderOverlay();
						}),
						catchError((error) => {
							this.closeLoaderOverlay();
							this.logService.log(LOG_TYPE.INFO, "error loading the page url", { page: this.pageName, error: error });

							let errorPageTemplate: PageTemplate;
							if (error && error.error && error.error.sections) {
								errorPageTemplate = error.error as PageTemplate;
							} else {
								errorPageTemplate = new PageTemplate({
									sections: [
										new PageSection({
											component: 'error-500'
										})
									]
								});
							}

							return of(errorPageTemplate);
						})
					)
				);
			this.pageTemplate = new PageTemplate({});
		}
	}

	//TODO REFACTOR THIS WHOLE SERVICE ALSO THIS FUNCTION

	private updateUrlIfItHasDynamicParams(url: string): string {
		const parts = url.split('/').filter(x => x);

		if (!parts.length) {
			return url;
		}

		// Removes query params
		if (parts[parts.length - 1].includes('?')) {
			parts[parts.length - 1] = parts[parts.length - 1].split('?')[0]
		}

		// Finding if we have dynamic parameter in the URL
		let _url = '/';
		parts.forEach(part => {
			if (Number(part) > 0) {
				_url = _url + ':id/';
			} else {
				_url = _url + part + '/'
			}
		})
		return _url;
	}

	closeLoaderOverlay() {
		const overlayElement = document.getElementById('cms-loading-overlay');
		overlayElement && (overlayElement.style.display = 'none');
	}

	routeToError(statusCode: Number): void {
		this.router.navigate(['/' + statusCode]);
	}

	getLayout() {
		const layoutKey = this.pageTemplate.template ? this.pageTemplate.template : 'default';
		return this.layoutDefinitions[layoutKey];
	}

	setPage(pageTemplate: PageTemplate) {
		this.pageConfigService.isBlogPage = Boolean(pageTemplate && pageTemplate.sections && pageTemplate.sections.find(e => e.component == 'blog-post-section'));
		this.initPage(pageTemplate);
	}

	proceedPageRequest(url: string): boolean {
		let pageName = this.getPageName(url);

		const forbiddenSubstrings = ['texts.', 'images.', 'video.', 'ROUTES.'];
		if (this.lastURL != pageName && !forbiddenSubstrings.some(v => pageName.includes(v))) {
			this.lastURL = pageName;
			return true;
		}
		return false;
	}

	setPageName(url: string = this.location.path()): void {
		this.pageName = this.getPageName(url);
	}

	getPageName(url: string = this.location.path()): string {
		// Remove the first / from the url, if it contains a / at the beginning
		let result = url.length && url[0] === "/" ? url.substring(1) : url;
		// Ignoring GET parameters
		result = result.split('?')[0];
		// Ignoring in app prefix
		result = result.replace('android_asset/www/', '');
		// Ignoring router outlets
		result = result.split('(')[0];

		result = new RegExp('\/[0-9][0-9]').test(result) ? result.split(new RegExp('\/[0-9][0-9]'))[0] : result;

		// Remove ids at the end of the url
		let nameSplit = result.split('/');
		if (!isNaN(Number(result.split('/').pop()))) {
			nameSplit.pop();
			result = nameSplit.join('/');
		}
		// if the initial url value was / or "", set to homepage
		if (!result) {
			result = 'homepage';
		}

		return result;
	}

	initPage(pageTemplate: PageTemplate): void {
		this.pageTemplate = this.mergeQuestionCollectionSections(pageTemplate);
		this.updatePageMetaTags();
		this.pageConfigService.setConfig(pageTemplate.config);
	}

	/**
	 * Merging multiple question-collection-sections into a different data structure for better display in Angular
	 * This is a work-around function. We need to fix it wholesome, by allowing multiple question-collections
	 * for the question-collection-section in the admin-create-page-tool.
	 * @param pageTemplate
	 * @returns {PageTemplate} PageTemplate with merged question-collection sections
	 */
	mergeQuestionCollectionSections(pageTemplate: PageTemplate): PageTemplate {
		if (pageTemplate.sections?.length) {
			let questionCollectionSections = pageTemplate.sections.filter(s => s.component == "question-collection");
			if (questionCollectionSections && questionCollectionSections.length > 1) {
				let collectionGroups = [];
				let lastSection = null;
				let groupIndex = 0;
				for (const section of questionCollectionSections) {
					if (lastSection && lastSection.position == section.position - 1) {
						const index = pageTemplate.sections.findIndex(e => e.position == section.position);
						// Removing section from pageTemplate Section so that it can be added in contentsCollection later
						pageTemplate.sections.splice(index, 1);
						collectionGroups[groupIndex].push(section);
						lastSection = section;
					} else if (lastSection && lastSection.position != section.position - 1) {
						groupIndex++;
					}
					if (!collectionGroups[groupIndex]) {
						collectionGroups[groupIndex] = [section];
						lastSection = section;
					}
				}
				for (const group of collectionGroups) {
					const index = pageTemplate.sections.findIndex(e => e.id == group[0].id);
					let targetSection: PageSection = pageTemplate.sections[index];
					targetSection.data['contentsCollections'] = [];
					for (const section of group) {
						targetSection.data.contentsCollections.push(new QuestionCollection(section.data.contents, section.data.seperatorTitle, section.data.collectionTitle));
					}
					pageTemplate.sections[index] = targetSection;
				}
			}
		}
		return pageTemplate;
	}

	updatePageMetaTags() {
		this.titleService.setTitle(this.pageTemplate.title);
		this.metaService.updateTag({ name: 'description', content: this.pageTemplate.description });
		this.metaService.updateTag({ property: 'og:locale', content: this.pageTemplate.country == 'nl' ? 'nl_NL' : 'de_DE' });
		this.metaService.updateTag({ property: 'og:title', content: this.pageTemplate.title });
		this.metaService.updateTag({ property: 'og:description', content: this.pageTemplate.description });
		let tempURL = '';
		if (this.utilityService.isBrowser) {
			tempURL = `${location.origin}/${this.location.path()}`;
		} else {
			// TODO check
			tempURL = `https://${this.request.get('host')}/${this.request.get('path')}`;
		}
		this.metaService.updateTag({ property: 'og:url', content: tempURL });
		// TODO international site_name
		this.metaService.updateTag({ property: 'og:site_name', content: this.pageTemplate.title });
		if (!this.pageTemplate.image) {
			this.translationService.get('images.Misc_logo_rebrand').subscribe(image => {
				let _image = this.utilityService.convertICUTranslatedObject(image);
				this.pageTemplate.image = _image && _image.sources ? _image.sources.large : "";
			});
		}
		if (!this.pageTemplate?.siteMap) {
			this.metaService.updateTag({ name: 'robots', content: "noindex" });
		} else {
			this.metaService.updateTag({ name: 'robots', content: "max-snippet:-1, max-image-preview:large, max-video-preview:-1" });
		}
		this.metaService.updateTag({ property: 'og:image', content: this.pageTemplate.image });
		this.metaService.updateTag({ property: 'og:image:secure', content: this.pageTemplate.image });
		this.metaService.updateTag({ name: 'twitter:title', content: this.pageTemplate.title });
		this.metaService.updateTag({ name: 'twitter:description', content: this.pageTemplate.description });
	}

	getPageTemplate(url: string | null = null): Observable<PageTemplate> {
		if (!url) {
			url = this.location.path();
		}
		this.setPageName(url);
		this.lastURL = this.pageName;
		const headers = this.utilityService.addHeaders(BypassAPIGlobalHandleEnums.All)

		url = `${this.utilityService.apiBase}/api/content/page/resolve?url=${this.pageName}&country=${this.utilityService.country}`;
		return this.apiService.getWithHeaders(url, headers, null, true).pipe(
			map(r => r.page),
			tap((page) => {
				this.transferState.set(makeStateKey(url), page);
			})
		);
	}

	/**
	 * Handle Http operation that failed.
	 * Let the app continue.
	 * @param operation - name of the operation that failed
	 * @param result - optional value to return as the observable result
	 */
	handleError<T>(operation = 'operation', result?: T, callback?: Function | null) {
		return (error: any): Observable<T> => {

			this.logService.log(LOG_TYPE.INFO, `${operation} failed:`, error);
			this.logService.report(LOG_TYPE.ERROR, typeof error === 'object' ? error.toString() : error);

			// Let the app keep running by returning an empty result.
			return of(result as T);
		};
	}

	// setupTestPage() {
	// 	if (this.pageName == 'all-cms') {
	// 		this.pageTemplate = {
	// 			id: 0,
	// 			template: 'default',
	// 			name: 'all-cms',
	// 			title: 'All CMS Title',
	// 			description: 'All CMS Description',
	// 			socialMediaTitle: '',
	// 			socialMediaDescription: '',
	// 			protected: true,
	// 			updated_at: "",
	// 			config: null,
	// 			country: CountryTypes.nl,
	// 			sections: [],
	// 			version: "",
	// 		};
	// 		Object.keys(this.createViewService.sectionDefinitions).forEach((componentKey) => {
	// 			this.pageTemplate.sections.push({
	// 				id: "0",
	// 				component: componentKey,
	// 				page: "0",
	// 				position: 0,
	// 				data: []
	// 			});
	// 		});

	// 		this.initPage(this.pageTemplate);
	// 		return;
	// 	}
	// }
}


export class TemplateConfig {
	showNavigation: boolean;
	showFooter: boolean;

	constructor(showNavigation: boolean, showFooter: boolean) {
		this.showNavigation = showNavigation;
		this.showFooter = showFooter;
	}
}
