import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { MobileSearchComponent } from '../mobile-search/mobile-search.component';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Course } from 'app/models/search-school.model';
import { AuthService, PopupService, UtilityService } from 'app/services';
import { SearchService } from 'app/services/search.service';
import { Subject, Subscription, debounceTime, distinctUntilChanged, filter, tap } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UserStateEnum } from 'app/microservice-clients/user';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  isGreaterThanMiddle: boolean;
  backButtonWasPressed = false;

  @Input() isOneInput: boolean;
  @Input() canSearchAfterDismissed: boolean = true;
  isMobile: boolean;

  postCodeInputChange = new Subject()
  postCodeInputChangeSubscription: Subscription;
  @Output() onSearch = new EventEmitter();
  @ViewChild('courseSelector') courseSelector: NgSelectComponent;

  private routerSubscription: Subscription;
  private mobileSearchRef: MatDialogRef<MobileSearchComponent>

  get UserStateEnum(): typeof UserStateEnum {
    return UserStateEnum;
  }

  constructor(
    public searchService: SearchService,
    public utilityService: UtilityService,
    public authService: AuthService,
    private translationService: TranslateService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private viewportScroller: ViewportScroller,
    private cdr: ChangeDetectorRef,
    private popupService: PopupService
  ) {
  }

  ngOnInit(): void {
    if (this.utilityService.isBrowser) {
      this.adjustSearchBasedOnScreenSize();
    }

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        const isBackNavigation = event.navigationTrigger === 'popstate';

        if (isBackNavigation && !this.utilityService.isCodeControlledBackNavigation) {
          this.backButtonWasPressed = true;
        }
        this.utilityService.isCodeControlledBackNavigation = false;
      }
    });
  }

  ngAfterViewInit() {
    this.applyPostCodeFilter()
  }

  search() {
    this.onSearch.emit();
    this.translationService.get('ROUTES.Misc_tutor_search').subscribe(translation => {
      this.router.navigate([translation], { queryParams: this.searchService.searchFilterQueryParams });
    });
    this.searchService.allowShowIsRecommended();
  }

  onAnySelectionChange() {
    this.searchService.setQueryParamObjBasedOnSelection();
    this.storeScroll();
    this.onSearch.emit();
    this.searchService.allowShowIsRecommended();
  }

  openMobileSearch(selectedInput?: any) {
    this.mobileSearchRef = this.popupService.openResponsiveDialog(MobileSearchComponent);
    this.mobileSearchRef.componentInstance.data = { selectedInput: selectedInput, search: () => this.search() }
    this.mobileSearchRef.backdropClick().subscribe(() => {
      if (!this.canSearchAfterDismissed) {
        this.utilityService.goBackOnMobile();
      }
    })
    this.mobileSearchRef.afterClosed().subscribe(() => {
      setTimeout(() => {
        // this setTimeout is needed because of the following issue:
        // this afterDismissed event is fired before the router navigation
        // so in other words backButtonWasPressed was not updated yet if back button was pressed
        if (this.canSearchAfterDismissed) {
          if (!this.backButtonWasPressed) {
            this.search()
          } else {
            this.backButtonWasPressed = false;
          }
        }
        else {
          // This hack is needed due to ngModel doesn't detect changes on a reference variable type with ng-select component
          this.searchService.selectedCourseIds = [...this.searchService.selectedCourseIds]
        }
      })
    })
  }

  applyPostCodeFilter() {
    this.postCodeInputChangeSubscription = this.postCodeInputChange.pipe(
      tap((postCode: string) => {
        this.searchService.searchFilterQueryParams.postCode = postCode;
      }),
      debounceTime(1000),
      distinctUntilChanged(),
      filter((postCode: string) => postCode.length >= 5)
    ).subscribe(() => {
      this.searchService.validatePostCodeAndSetCoordinates().then(resp => {
        this.onAnySelectionChange();
      })
    });
  }

  removeCourse(course: Course) {
    this.searchService.selectedCourseIds = this.searchService.selectedCourseIds.filter(x => x != course.id);
    this.searchService.selectedCourses = this.searchService.selectedCourses.filter(x => x.id != course.id);
    this.searchService.setQueryParamObjBasedOnSelection();
  }

  ngOnDestroy(): void {
    this.postCodeInputChangeSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
  }

  private storeScroll() {
    this.searchService.storedScrollPosition = this.viewportScroller.getScrollPosition()[1];
  }

  private adjustSearchBasedOnScreenSize() {
    const isMobile = "(min-width: 576px)";
    this.breakpointObserver.observe([
      isMobile
    ]).subscribe((result: BreakpointState) => {
      if (result.breakpoints[isMobile]) {
        this.mobileSearchRef?.close();
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
      this.cdr.detectChanges();
    });
  }
}